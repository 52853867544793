export const env = {
    apiBaseUrl: 'https://gateway.endazambia.com/api/',
    // apiBaseUrl: 'https://rsastage-gateway.signitydemo.in/api/',
    imageUrl: 'https://d35qfhyy6v1c1z.cloudfront.net/',

    s3BucketDetail: {
      imageUrl: 'https://d35qfhyy6v1c1z.cloudfront.net/',
      bucketName: 'rsa-development-bucket',
      detail: {
        region: 'af-south-1',
        credentials: {
          accessKeyId: 'AKIA4SWDJLZSTY6WMZMR',
          secretAccessKey: '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',
        },
      },
    },
  }
