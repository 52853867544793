import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-more',
  templateUrl: './add-more.component.html',
  styleUrls: ['./add-more.component.scss']
})
export class AddMoreComponent {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  submit(): void {
    this.activeModal.close(true);
  }

  close(): void {
    this.activeModal.close();
  }

}
