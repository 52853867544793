import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MobileSettingsService {
  constructor(private http: HttpClient) {}

  public httpGetList(
    limit?: number,
    page?: number,
    search?: string,
    country_id?: string,
    city_id?: string,
    accounts?: string,
    startDate?: string,
    endDate?: string,
  ) {
    let params = new HttpParams();

    if (limit) {
      params = params.append('limit', limit.toString());
    }
    if (page) {
      params = params.append('page', page.toString());
    }
    if (search) {
      params = params.append('search', search);
    }
    if (country_id) {
      params = params.append('country_id', country_id);
    }
    if (city_id) {
      params = params.append('city_id', city_id);
    }
    if (accounts) {
      params = params.append('is_active', accounts);
    }
    if (startDate) {
      params = params.append('start_date', startDate);
    }
    if (endDate) {
      params = params.append('end_date', endDate);
    }
    return this.http.get<HttpResponse<any>>(`${API_Routes.Mobile.LIST}`, {
      params: params,
    });
  }

  public httpGetCountry(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(API_Routes.Mobile.GET_COUNTRIES);
  }

  public httpGetCities(country_id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      `${API_Routes.Mobile.GET_CITIES}/${country_id}`,
    );
  }

  public httpGetRateDistance(
    mobile_setting_id: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      `${API_Routes.Mobile.RATE_DISTANCE}/${mobile_setting_id}`,
    );
  }

  public httpGetPeakHours(
    mobile_setting_id: string,
  ): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      `${API_Routes.Mobile.PEAK_HOURS}/${mobile_setting_id}`,
    );
  }

  public httpPostRateDistance(
    country_id: string,
    city_id: string,
    payload: any,
  ): Observable<HttpResponse<any>> {
    const url = city_id ? `${country_id}/${city_id}` : `${country_id}`;
    return this.http.post<HttpResponse<any>>(
      `${API_Routes.Mobile.RATE_DISTANCE}/${url}`,
      payload,
    );
  }

  public httpPostPeakHours(
    country_id: string,
    city_id: string,
    payload: any,
  ): Observable<HttpResponse<any>> {
    const url = city_id ? `${country_id}/${city_id}` : `${country_id}`;
    return this.http.post<HttpResponse<any>>(
      `${API_Routes.Mobile.PEAK_HOURS}/${url}`,
      payload,
    );
  }

  public httpUpdateRateDistance(
    country_id: string,
    city_id: string,
    id: string,
    payload: any,
  ): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(
      `${API_Routes.Mobile.RATE_DISTANCE}/${id}/${country_id}/${city_id}`,
      payload,
    );
  }

  public httpUpdatePeakHours(
    country_id: string,
    city_id: string,
    id: string,
    payload: any,
  ): Observable<HttpResponse<any>> {
    const url = city_id ? `${country_id}/${city_id}` : `${country_id}`;
    return this.http.patch<HttpResponse<any>>(
      `${API_Routes.Mobile.PEAK_HOURS}/${url}/${id}`,
      payload,
    );
  }

  public httpDeletRateDistance(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${API_Routes.Mobile.RATE_DISTANCE}/${id}`,
    );
  }

  public httpDeletPeakHours(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${API_Routes.Mobile.PEAK_HOURS}/${id}`,
    );
  }

  public httpUpdateSettingsStatus(
    flag: string,
    mobileSettingId: string,
  ): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_Routes.Mobile.LIST}/${mobileSettingId}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }

  public httpAddMobileSetting(
    payLoad?: any,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      `${API_Routes.Mobile.ADD}`,
      payLoad,
    );
  }

  public httpUpdateMobileSetting(
    payload: any,
    id: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_Routes.Mobile.UPDATE}/${id}`,
      payload,
    );
  }

  public httpDeleteMobileSetting(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${API_Routes.Mobile.LIST}/${id}`,
    );
  }

  public httpGetMobileSettingsById(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${API_Routes.Mobile.LIST}/${id}`);
  }
}
