import { Injectable } from '@angular/core';
import { filter, map, Subject, Subscription } from 'rxjs';
import { EmitEvent } from '../helpers';
import { Events } from '../enums';

@Injectable()
export class EventBusService {
  private e_subject = new Subject<EmitEvent>();

  constructor() {}

  public on(event: Events, action: any): Subscription {
    return this.e_subject
      .pipe(
        filter((e: EmitEvent) => e.name == event),
        map((e: EmitEvent) => e.value),
      )
      .subscribe(action);
  }

  public emit(event: EmitEvent) {
    this.e_subject.next(event);
  }
}
