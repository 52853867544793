import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-reason',
  templateUrl: './report-reason.component.html',
  styleUrls: ['./report-reason.component.scss']
})
export class ReportReasonComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  contentType: string = '';
  
  contentMessage: any = ''; // Input property to receive the reason

  constructor(private activemodal: NgbActiveModal) { }

  ngOnInit(): void { }

  closemodal() { this.activemodal.close(); }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
