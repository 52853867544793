import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from './main.service';
const fileSizeMax = 1 * 1024 * 1024;
const widthHeightMax = 1024;
const defaultWidthHeightRatio = 1;
const defaultQualityRatio = 0.3;

@Injectable({
  providedIn: 'root'
})
export class CompressImageService {

  constructor(private zone: NgZone, private mainService: MainService) { }

  compress(file: File, originalimage?: any): Observable<File> {
    const isSizeExceed = originalimage.size >= 1024 * 1024;
    const imageType = file.type || 'image/jpeg';
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return Observable.create((observer: any) => {
      this.zone.run(() => {
        reader.onload = (ev) => {
          const img = this.createImage(ev);
          const imgWH = img.width > img.height ? img.width : img.height;
          let withHeightRatio = imgWH > widthHeightMax ? widthHeightMax / imgWH : defaultWidthHeightRatio;
          let qualityRatio = isSizeExceed ? defaultQualityRatio : 0.6;
          img.onload = () => {
            this.mainService.imageLoaded();
            const elem = document.createElement('canvas');
            elem.width = img.width * withHeightRatio;
            elem.height = img.height * withHeightRatio;
            const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
            ctx.drawImage(img, 0, 0, elem.width, elem.height);
            ctx.canvas.toBlob(
              (blob: any) => {
                observer.next(new File([blob], file.name, { type: imageType, lastModified: Date.now() }));
              }, imageType, 0.7 || qualityRatio // reduce image quantity
            );
          };
        };
        reader.onerror = (error) => observer.error(error);
      })
    });
  }

  private createImage(ev: any) {
    let imageContent = ev.target.result;
    const img = new Image();
    img.src = imageContent;
    return img;
  }

  compressFile2(fileImage: File): Promise<any> {
    const QUALITY = 0.9;
    return new Promise((resolve, reject) => {
      const file = fileImage; // get the file
      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onerror = function () {
        URL.revokeObjectURL(img.src);
        reject('Cannot load image')
      };
      return img.onload = () => {
        URL.revokeObjectURL(img.src);
        const imgWH = img.width > img.height ? img.width : img.height;
        let withHeightRatio = 0.25;
        const [newWidth, newHeight] = this.calculateSize(img, img.width * withHeightRatio, img.height * withHeightRatio);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = <CanvasRenderingContext2D>canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        return canvas.toBlob(
          (blob: any) => {
            let newfile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            })
            resolve(newfile)
          },
          file.type,
          QUALITY
        );
      };
      // Utility functions for demo purpose
    })
  }

  calculateSize(img: any, maxWidth: any, maxHeight: any) {
    let width = img.width;
    let height = img.height;
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }
}
