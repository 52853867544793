<!-- Modal -->
<div class="modal1">
  <div class="modal-dialog m-2 modal-dialog-centered">
    <div class="modal-content border-0">
      <div class="modal-header border-0 p-0 justify-content-center">
        <h4 class="text-center mt-1 mb-0">Vehicle Details</h4>
        <button type="button" (click)="close()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <hr class="opacity-1">
      <div class="modal-body border-0 m-0 pb-0 text-center">
        <img src="https://t4.ftcdn.net/jpg/00/15/31/49/360_F_15314985_IVd2YS0CsZd5EOactDkKYN3tawwQcSEl.jpg" width="200px">
        <div>Vehicle Details Added Successfully</div>
      </div>
      <div class="modal-footer border-0 justify-content-center mt-0 mb-3 me-2">
        <button type="button" (click)="submit()" class="btn btn-primary rounded-pill">
          <i class="far fa-plus"></i> Add More
        </button>
        <button class="" (click)="close()" class="btn btn-outline-success rounded-pill">Continue</button>
      </div>
    </div>
  </div>
</div>
