import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomMessageService, MainService } from '../../services';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  token: string = ''
  email: string = ''
  constructor(public activatedRoute: ActivatedRoute, private _mainService: MainService, private router: Router, private toastService: CustomMessageService) {
    this.activatedRoute.queryParams.subscribe(param => {
      if (param['email']) {
        this.email = param['email']
      }
      if (param['token']) {
        this.token = param['token']
      }
    })
  }

  verifyAccount(): void {
    const req = {
      token: this.token,
      email: this.email
    }
    this._mainService.httpVerifyEmail(req).subscribe(res => {
      this.router.navigate(['/']);
      this.toastService.show('Thank you for registering with us. Your email verified successfully', 'success', 'Success');
    })
  }

}
