import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/app/shared/constants';
import { TermsPagesSchema } from '../interfaces/terms-pages.interface';
import { Observable } from 'rxjs';
import { HttpResponse } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class TermsPagesService {
  constructor(private http: HttpClient) {}

  public httpPostPage(
    body: TermsPagesSchema,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(
      API_Routes.TERMS_PAGES.LIST,
      body,
    );
  }

  public httpGetPageDetail(type: string) {
    return this.http.get(`${API_Routes.TERMS_PAGES.LIST}/${type}`);
  }

  public httpUpdatePageDetail(
    body: TermsPagesSchema,
    id: string,
  ): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_Routes.TERMS_PAGES.LIST}/${id}`,
      body,
    );
  }
}
