import { Injectable } from '@angular/core';
import { ERROR_MESSAGES, ErrorTypes } from '../public-api';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  getErrorValidationMessage(
    formControlName: string,
    errors: [string, any][],
  ): string {
    switch (true) {
      case this.checkErrorType(errors, 'required'):
        return ERROR_MESSAGES['required'](formControlName);

      case this.checkErrorType(errors, 'email'):
        return ERROR_MESSAGES['email']();

      case this.checkErrorType(errors, 'pattern'):
        return ERROR_MESSAGES['pattern'](formControlName);

      case this.checkErrorType(errors, 'hasCapitalCase'):
        return ERROR_MESSAGES['hasCapitalCase']();

      case this.checkErrorType(errors, 'hasSmallCase'):
        return ERROR_MESSAGES['hasSmallCase']();

      case this.checkErrorType(errors, 'hasSpecialCharacters'):
        return ERROR_MESSAGES['hasSpecialCharacters']();

      case this.checkErrorType(errors, 'hasNumber'):
        return ERROR_MESSAGES['hasNumber']();

      case this.checkErrorType(errors, 'noWhiteSpaceValidator'):
        return ERROR_MESSAGES['noWhiteSpaceValidator']();

      case this.checkErrorType(errors, 'minlength'):
        const minRequirement = this.getErrorMessage(
          errors,
          'minlength',
        )?.requiredLength;
        return ERROR_MESSAGES['minlength'](formControlName, minRequirement);

      case this.checkErrorType(errors, 'maxlength'):
        const maxRequirement = this.getErrorMessage(
          errors,
          'maxlength',
        )?.requiredLength;
        return ERROR_MESSAGES['maxlength'](formControlName, maxRequirement);
      default:
        return '';
    }
  }

  checkErrorType(errors: [string, any][], key: ErrorTypes) {
    return errors.some(([errorKey, value]) => errorKey === key);
  }

  getErrorMessage(errors: [string, any][], key: ErrorTypes) {
    return errors.find(([k, v]) => k === key)?.[1];
  }
}
