export enum PERMISSION_KEYS {
  ADMIN = 'admin',

  DASHBOARD = 'dashboard',

  // users
  USERS = 'users',
  DRIVERS = 'drivers',
  ROLES = 'roles',

  TRAVEL_PREFERENCES = 'travelPreferences',
  // Manage Documents
  MANAGE_DOCUMENTS = 'Manage Documents',
  DOCUMENT_QUESTIONNAIRE = 'Document Questionnaire',

  MANAGE_VEHICLE = 'manageVehicle',

  //Manage Booking
  REFUND_PROCESS = 'refundProcess',
  OFFER_RIDES = 'offerRides',
  BOOKINGS = 'bookings',

  // complaints
  COMPLAINTS = 'complaints',

  // coupon
  COUPON = 'coupon',

  // reports
  REPORTS = 'reports',

  // Manage Price
  PRICE_CATALOGUE = 'priceCatalogue',
  COMMISSION = 'commission',
  FINANCIAL_YEAR = 'financialYear',

  // Informative
  PRIVACY_POLICY = 'privaryPolicy',
  TERMS_CONDITIONS = 'termsConditions',
  FAQ = 'faq',
  ABOUT_US = 'AboutUs',
  SUPPORT_CONTACT = 'support',

  // Settings
  SETTINGS = 'settings',
  NOTIFICATION = 'manageNotifications',

  SEND_NOTIFICATION = 'sendNotifications',

  SPAMREPORT = 'spamReport ',
}
