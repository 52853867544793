<ng-container *ngIf="isVisible">
  <div class="overlay-div">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-container>
