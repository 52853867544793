<div class="d-flex justify-content-between mt-4">
  <div class="col-md-4 d-flex align-items-center">
    <label for="limit" class="me-2">Per page</label>
    <select class="form-control form-select w-auto" [(ngModel)]="config.itemsPerPage"
      (ngModelChange)="changeLimit($event)">
      <option *ngFor="let num of limitArray" [value]="num">
        {{ num || '' }}
      </option>
    </select>
  </div>

  <nav aria-label="Page navigation example col-md-8">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" [ngClass]="{ disabled: !previousPage }" (click)="changeToFirstPage()" aria-label="First">
          <span aria-hidden="true"><small>{{firstPageArrow}}</small></span>
          <span class="sr-only">FirstPage</span>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" [ngClass]="{ disabled: !previousPage }" (click)="changeToPreviousPage()"
          aria-label="Previous">
          <span aria-hidden="true" style="font-size: smaller;">
            <small>{{backArrow}} </small></span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="page-item" (click)="changeToPreviousPage()" *ngIf="previousPage">
        <a class="page-link">{{ previousPage }}</a>
      </li>
      <li class="page-item active" *ngIf="currentPage">
        <a class="page-link">{{ currentPage }}</a>
      </li>
      <li class="page-item" *ngIf="nextPage">
        <a class="page-link" (click)="changeToNextPage()">{{ nextPage }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" [ngClass]="{ disabled: !nextPage }" (click)="changeToNextPage()" aria-label="Next">
          <span aria-hidden="true"><small>{{nextArrow}}</small></span>
          <span class="sr-only">Next</span>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" [ngClass]="{ disabled: !nextPage }" (click)="changeToLastPage()" aria-label="Last">
          <span aria-hidden="true"><small>{{lastPageArrow}}</small></span>
          <span class="sr-only">LastPage</span>
        </a>
      </li>
    </ul>
  </nav>
</div>