export enum PageType {
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_CONDITIONS = 'terms-conditions',
  ABOUT_US = 'about-us',
  FAQ = 'faq',
  SUPPORT_AND_CONTACT = 'support-and-contact',
  DELETE_DATA = 'delete-data'
}

export type PageTypes = 'privacy-policy' | 'terms-conditions' | 'about-us' | 'faq' | 'support-and-contact' | 'delete-data';
